import { useEffect } from 'react';
// import { Suspense, lazy} from 'react';
import 'App.css';
// import LoginSignupPages from 'pages/loginSignupPages';
// import Loader from 'components/CustomeComponents/Loader';
// import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';
// import {
//     ROUTES_CATOGERY,
//     ROUTES_HOME,
//     ROUTES_LOGIN,
//     ROUTES_PRODUCT_DETAILS,
//     ROUTES_USER_PROFILE,
//     ROUTES_USER_WALLETS,
// } from 'utils/constants_data/pages_routes';
// import PrivateRoute from 'PrivateRoute';
// import 'react-toastify/dist/ReactToastify.css';
// const HomePAge = lazy(() => import('pages/homePage'));
// const CatogeriesPage = lazy(() => import('pages/catogriesPage'));
// const ProductDetailsPage = lazy(() => import('pages/productDetailsPage'));
// const UserWalletsPage = lazy(() => import('pages/UserWalletsPage'));
// const UserProfilePage = lazy(() => import('pages/UsersProfilePage'));

function App() {
    useEffect(() => {
        // Redirect to the desired URL
        window.location.href = 'https://www.monitree.co/id';
    }, []);
    return (
        // <Suspense fallback={<Loader />}>
        //     <BrowserRouter>
        //         <Routes>
        //             <Route exact path={ROUTES_LOGIN} element={<LoginSignupPages />} />
        //             <Route element={<PrivateRoute />}>
        //                 <Route exact path={ROUTES_HOME} element={<HomePAge />} />
        //                 <Route exact path={ROUTES_CATOGERY} element={<CatogeriesPage />} />
        //                 <Route exact path={ROUTES_PRODUCT_DETAILS} element={<ProductDetailsPage />} />
        //                 <Route exact path={ROUTES_USER_WALLETS} element={<UserWalletsPage />} />
        //                 <Route exact path={ROUTES_USER_PROFILE} element={<UserProfilePage />} />
        //             </Route>
        //         </Routes>
        //     </BrowserRouter>
        // </Suspense>

        <></>
    );
}

export default App;
